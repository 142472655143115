.swiper {
  --swiper-pagination-bottom: 0;

  .slider-nav-button.slider-disabled {
    @apply shadow-sm;
    background-color: rgb(175, 175, 175);
    color: rgb(240, 240, 240);
    opacity: 0;
    transition-delay: 0.2s;
    transition-duration: 0.4s;
    transition-property: opacity, background-color, color;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  }

  .slider-disabled:hover {
    opacity: 0.5;
  }

  .swiper-wrapper {
    align-items: top;
    @apply pb-7 md:pb-0;
  }

  .swiper-pagination {
    @apply block md:hidden -mt-7;
    top: calc(100%);
    transform: none;
  }

  .swiper-pagination-bullet {
    @apply bg-primary;
  }
}
