.main {
  @apply p-[var(--page-x)] md:p-[var(--page-x-md)] max-w-[1920px] w-full mx-auto flex-grow;
}

.fullScreen {
  @apply p-0 md:p-0 max-w-none;
}

.unmounted {
  @apply opacity-0 transition-opacity duration-200 ease-in-out;
}

.mounted {
  @apply opacity-100 transition-opacity duration-200 ease-in-out;
}
