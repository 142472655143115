.toast {
  @apply max-w-md w-full bg-white shadow-lg rounded-2xl pointer-events-auto flex border gap-5 p-5 items-center;

  &--danger {
    @apply border-danger-500 text-danger-500;
  }

  &--success {
    @apply border-success-500 text-success-500;
  }

  &--warning {
    @apply border-warning-500 text-warning-500;
  }

  &--info {
    @apply border-secondary-400 text-secondary-400;
  }

  &__content {
    @apply flex-1 flex flex-col items-start;

    &__title {
      @apply text-xl text-black-500;
    }

    &__description {
      @apply text-sm text-secondary-600 mt-0.5;
    }
  }

  &__close-button {
    @apply h-6 w-6 self-start;
  }

  &__icon {
    @apply h-12 w-12;
  }

  &--animate-enter {
    @apply animate-enter;
  }

  &--animate-leave {
    @apply animate-leave;
  }
}
