
:root {
  --color-dark: 0 0 0;
--color-light: 255 255 255;
--color-neutral-50: 249 250 251;
--color-neutral-100: 243 244 246;
--color-neutral-200: 229 231 235;
--color-neutral-300: 209 213 219;
--color-neutral-400: 156 163 175;
--color-neutral-500: 107 114 128;
--color-neutral-600: 75 85 99;
--color-neutral-700: 55 65 81;
--color-neutral-800: 31 41 55;
--color-neutral-900: 17 24 39;
--color-neutral: 107 114 128;
--color-primary-50: 254 236 231;
--color-primary-100: 253 218 208;
--color-primary-200: 252 173 161;
--color-primary-300: 246 119 114;
--color-primary-400: 238 78 87;
--color-primary-500: 227 24 54;
--color-primary-600: 195 17 60;
--color-primary-700: 163 12 62;
--color-primary-800: 131 7 60;
--color-primary-900: 108 4 58;
--color-primary: 227 24 54;
--color-secondary-50: 252 251 247;
--color-secondary-100: 242 240 231;
--color-secondary-200: 223 219 203;
--color-secondary-300: 201 195 170;
--color-secondary-400: 181 173 144;
--color-secondary-500: 149 141 112;
--color-secondary-600: 123 115 85;
--color-secondary-700: 98 90 64;
--color-secondary-800: 75 68 45;
--color-secondary-900: 55 49 29;
--color-secondary: 149 141 112;
--color-success-50: 238 242 255;
--color-success-100: 209 250 229;
--color-success-200: 199 210 254;
--color-success-300: 165 180 252;
--color-success-400: 129 140 248;
--color-success-500: 16 185 129;
--color-success-600: 79 70 229;
--color-success-700: 67 56 202;
--color-success-800: 55 48 163;
--color-success-900: 49 46 129;
--color-success: 16 185 129;
--color-danger-50: 238 242 255;
--color-danger-100: 224 231 255;
--color-danger-200: 199 210 254;
--color-danger-300: 165 180 252;
--color-danger-400: 129 140 248;
--color-danger-500: 239 68 68;
--color-danger-600: 79 70 229;
--color-danger-700: 67 56 202;
--color-danger-800: 55 48 163;
--color-danger-900: 49 46 129;
--color-danger: 239 68 68;
--color-warning-50: 238 242 255;
--color-warning-100: 224 231 255;
--color-warning-200: 199 210 254;
--color-warning-300: 165 180 252;
--color-warning-400: 251 191 36;
--color-warning-500: 245 158 11;
--color-warning-600: 79 70 229;
--color-warning-700: 67 56 202;
--color-warning-800: 55 48 163;
--color-warning-900: 120 53 15;
--color-warning: 245 158 11;
--color-info-50: 238 242 255;
--color-info-100: 224 231 255;
--color-info-200: 199 210 254;
--color-info-300: 165 180 252;
--color-info-400: 129 140 248;
--color-info-500: 59 130 246;
--color-info-600: 79 70 229;
--color-info-700: 67 56 202;
--color-info-800: 55 48 163;
--color-info-900: 49 46 129;
--color-info: 59 130 246;
}
@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --navbar-height: 114px;
  --page-x-md: 5rem;
  --page-x: 1.25rem;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

* {
  margin: 0;
}

html,
body {
  height: 100%;
}

body {
  -webkit-font-smoothing: antialiased;
  line-height: 1.5;
  @apply bg-secondary-50;
}

img,
picture,
video,
canvas,
svg {
  display: block;
  max-width: 100%;
}

input,
button,
textarea,
select {
  font: inherit;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
}

#root,
#__next {
  isolation: isolate;
}

#__next {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  opacity: 0.5;
}

// Remove ugly X (reset button) from Chrome search input
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

.react-masonry-list > * {
  visibility: visible !important;
}
