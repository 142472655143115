.Button {
  @apply border border-transparent font-medium leading-4 shadow-sm duration-200 ease-in-out;
  transition-property: background-color, color;

  // Round if no rounding class is specified
  &:not([class*="rounded"]) {
    @apply rounded-lg;
  }

  // Sizes
  &--xs {
    @apply px-2.5 py-1 text-xs;
  }

  &--sm {
    @apply px-3 py-1.5 text-sm;
  }

  &--md {
    @apply px-5 py-2 text-sm;
  }

  &--long {
    @apply px-3 py-2 text-base;
  }

  &--lg {
    @apply px-5 py-2 text-base;
  }

  &--xl {
    @apply px-6 py-3 text-base;
  }

  &:disabled,
  .Button--disabled {
    @apply text-neutral-400 bg-neutral-200 cursor-not-allowed;
  }

  &:focus-visible {
    @apply ring-2 ring-offset-2 ring-primary-500;
  }

  // Colors
  // We make a negative check on disabled because it allows this styling to work with tags
  // that don't have enabled/disabled states
  &:not(:disabled) {
    &.Button--neutral {
      @apply text-neutral-600-contrast bg-neutral-500 hover:bg-neutral-600 active:bg-neutral-700 ring-neutral-500;
    }

    &.Button--primary {
      @apply text-primary-600-contrast bg-primary-500 hover:bg-primary-600 active:bg-primary-700 ring-primary-500;
    }

    &.Button--success {
      @apply text-success-600-contrast bg-success-500 hover:bg-success-600 active:bg-success-700 ring-success-500;
    }

    &.Button--danger {
      @apply text-danger-600-contrast bg-danger-500 hover:bg-danger-600 active:bg-danger-700 ring-danger-500;
    }

    &.Button--warning {
      @apply text-warning-600-contrast bg-warning-500 hover:bg-warning-600 active:bg-warning-700 ring-warning-500;
    }

    &.Button--info {
      @apply text-info-600-contrast bg-info-500 hover:bg-info-600 active:bg-info-700 ring-info-500;
    }

    // Non-global variants
    &.Button--basic {
      @apply text-neutral-700 bg-white border border-neutral-300 hover:bg-neutral-50 active:bg-neutral-100 ring-primary-600;
    }

    &.Button--secondary {
      @apply text-primary-700 bg-primary-100 hover:bg-primary-200 active:bg-primary-300 ring-primary-600;
    }

    &.Button--primary-dark {
      @apply text-secondary-200 bg-secondary-900;
    }

    &.Button--primary-light {
      @apply text-secondary-600 bg-secondary-100 hover:bg-secondary-200;
    }
  }
}
